import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeModal } from 'common/slice/modalSlice';
import { useState } from 'react';
import { extendSessionInFirebase } from 'helpers/sessionExtend';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { v4 as uuidv4 } from 'uuid';
import { createNewMessage } from 'helpers/messages';
import { handleError } from 'helpers/sentry';
import { Mixpanel } from '../../Mixpanel';

interface Props {
  sessionId: string;
}

const ExtendModal = ({ sessionId }: Props) => {
  const { id } = useAppSelector((state) => state.currentUser);
  const [extendTime, setExtendTime] = useState<number>(15);
  const [extendReason, setExtendReason] = useState('');
  const dispatch = useAppDispatch();

  const extendSession = async () => {
    dispatch(closeModal({}));
    extendSessionInFirebase(sessionId, extendTime, extendReason);
    sendChatMessage();
    Mixpanel.track("proctoring_session_extended");
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value && parseInt(value) <= 500) {
      setExtendTime(parseInt(value));
    } else {
      setExtendTime(0);
    }
  };

  const sendChatMessage = async () => {
    try {
      const messageData = {
        created_at: new Date().toISOString(),
        id: uuidv4(),
        user_id: id,
        role: 'proctor',
        text: "Your proctored session has been extended by " + extendTime + " minutes. Reason: " + extendReason,
      };
      await createNewMessage(sessionId, messageData);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="flex gap-3">
      <div className="flex-1 flex flex-col mt-1.5">
        <p className="font-bold text-lg">Extend Session</p>
        <p className="mb-2 text-zinc-500">Please specify the additional time in minutes and provide a reason for extending this session</p>
        <div className='flex flex-row mt-5'>
          <label htmlFor="extendTime" className="text-zinc-500">
            Extra time
          </label>
          <input
            id="numericInput"
            data-testid="extendedTime"
            type="number"
            value={extendTime === 0 ? '' : extendTime}
            onChange={handleTimeChange}
            placeholder="Enter numbers only"
            min="0"
            max="500"
            className='ml-2 p-1 rounded-md border w-40'
          />
        </div>
        <div className='flex flex-row mt-3'>
          <label htmlFor="extendComments" className="flex flex-row text-zinc-500">
            Reason
            <div className="relative w-max mx-auto mt-1">
              <span className="cursor-pointer relative group">
                <InformationCircleIcon className="h-4 w-4 text-black" />
                <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-max bg-gray-800 text-white text-sm rounded px-3 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 shadow-lg pointer-events-none">
                  Reason provided will also be shown to the candidate in the chat
                  <div className="absolute top-full left-1/2 -translate-x-1/2 border-4 border-t-gray-800 border-r-transparent border-b-transparent border-l-transparent"></div>
                </div>
              </span>
            </div>
          </label>
          <textarea
            id="extendComments"
            value={extendReason}
            onChange={(e) => setExtendReason(e.target.value)}
            className="ml-2 p-2 rounded-md border w-60"
            placeholder="Add text"
            cols={10}
            rows={3}
          ></textarea>
        </div>
        <p className="mb-2 text-zinc-500">Note: This action only extends the proctoring duration, and not the exam timer in the application. The exam will end if the timer runs out, regardless of extension.</p>
        <div className="mt-3 ml-auto flex gap-1 text-sm">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button
            disabled={!(extendReason && extendReason.length > 0 && extendTime)}
            className="primary-btn"
            onClick={extendSession}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExtendModal;
