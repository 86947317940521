import Tile from 'components/tiles/Tile';
import AttendeePane from './AttendeePane';
import { Attendee } from 'common/api/proctorType';
import { ParticipantData } from 'hooks/useLiveStream';
import { useAppDispatch } from 'app/hooks';
import { isApprovalRequired } from 'helpers/prechecks';
import { Precheck, Session } from 'globals/interfaces';
import { hasUnreadMessages } from 'helpers/messages';
import { getAvailableMediaSettings, isSessionEnded } from 'helpers/session';
import { openModal } from 'common/slice/modalSlice';
import { SessionStatus } from 'globals/enums';
import CameraAndScreenGrabsList from './CameraAndScreenGrabsList';
import { hasUnreadCriticalAlerts } from 'helpers/alerts';
import { getSessionCompletedAt, getSessionJoinedAt } from 'helpers/sessionDetails';
import Button from './Button';

interface Props {
  participants: ParticipantData[];
  session: Session;
  sessionId: string;
  attendee: Attendee;
}

const AttendeeDetail = ({ sessionId, session, attendee, participants }: Props) => {
  const participant = participants.find((item: ParticipantData) => item.session_id === sessionId);
  const dispatch = useAppDispatch();
  const prechecks: Precheck[] = session?.pre_check ? Object.values(session?.pre_check) : [];

  const isApprovalPending = isApprovalRequired(prechecks, session?.configuration);
  const messages = session?.messages ? Object.values(session.messages) : [];
  const alerts = session?.alerts ? Object.values(session.alerts) : [];
  const sessionCompletedAt = getSessionCompletedAt(alerts);
  const sessionJoinedAt = getSessionJoinedAt(alerts);
  const isChatUnread = hasUnreadMessages(messages);
  const isCriticalAlertUnread = hasUnreadCriticalAlerts(alerts);

  const handleTerminate = () => {
    dispatch(
      openModal({
        attendeeId: attendee.attendee_id,
        type: SessionStatus.Terminated,
        sessionUuid: session.session_uuid,
        sessionId: session.session_id,
        slotEndDate: session.session_end_date,
        slotStartDate: session.session_start_date,
      }),
    );
  };

  const handleAddFlag = () => {
    dispatch(
      openModal({
        attendeeId: attendee.attendee_id,
        type: 'add-flag',
        sessionUuid: session.session_uuid,
        sessionId: session.session_id,
        slotEndDate: session.session_end_date,
        slotStartDate: session.session_start_date,
      }),
    );
  };

  const handlePause = () => {
    dispatch(
      openModal({
        attendeeId: attendee.attendee_id,
        type: 'pause',
        sessionUuid: session.session_uuid,
        sessionId: session.session_id,
        slotEndDate: session.session_end_date,
        slotStartDate: session.session_start_date,
      }),
    );
  };

  const handleExtend = () => {
    dispatch(
      openModal({
        attendeeId: attendee.attendee_id,
        type: 'extend',
        sessionUuid: session.session_uuid,
        sessionId: session.session_id,
        slotEndDate: session.session_end_date,
        slotStartDate: session.session_start_date,
      }),
    );
  };

  const handleResume = () => {
    dispatch(
      openModal({
        attendeeId: attendee.attendee_id,
        type: 'resume',
        sessionUuid: session.session_uuid,
        sessionId: session.session_id,
        slotEndDate: session.session_end_date,
        slotStartDate: session.session_start_date,
      }),
    );
  };

  return (
    <div className="flex flex-col h-screen gap-2">
      <div
        className="relative flex flex-col items-center justify-center bg-gray-300 h-1/2 max-h-1/2"
        style={{ backgroundColor: '#0F172A' }}
      >
        <div style={{ height: 'calc(100% - 24px)' }} className="flex items-center justify-center">
          <Tile
            availableSettings={getAvailableMediaSettings(session.configuration)}
            sessionStatus={session.session_status}
            attendeeId={attendee.attendee_id}
            isApprovalPending={isApprovalPending}
            participant={participant}
          />
        </div>
        <div className="flex flex-row items-center justify-between w-full h-6 p-4 bg-blue-100">
          <AttendeePane
            sessionCompletedAt={sessionCompletedAt}
            sessionJoinedAt={sessionJoinedAt}
            tooltipId={`detail_tooltip_${attendee.attendee_id}`}
            isChatUnread={isChatUnread}
            isApprovalPending={isApprovalPending}
            attendee={attendee}
            sessionId={sessionId}
            sessionUuid={session.session_uuid}
            sessionStatus={session?.session_status}
            isCriticalAlertUnread={isCriticalAlertUnread}
            isPaused={session?.pause}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2 p-2 h-1/10">
        {session.session_status === SessionStatus.Online && !session?.pause && (
          <Button
            label="Pause"
            className="px-3 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => handlePause()}
            type="button"
          />
        )}
        {session.session_status === SessionStatus.Online && session?.pause && (
          <Button
            label="Resume"
            className="px-3 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => handleResume()}
            type="button"
          />
        )}
        <Button
          label="Extend"
          className="px-3 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm disabled:bg-blue-300 enabled:hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={() => handleExtend()}
          type="button"
          disabled={isSessionEnded(session.session_status)}
        />
        <Button
          label="Terminate"
          className="px-3 py-2 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm disabled:bg-red-300 enabled:hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
          onClick={() => handleTerminate()}
          type="button"
          disabled={isSessionEnded(session.session_status)}
        />
        <Button
          label="Add flag"
          className="px-3 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={() => handleAddFlag()}
          type="button"
        />
      </div>
      <div className="mt-6 h-2/5 att-det-image-container">
        <CameraAndScreenGrabsList sessionId={sessionId} session={session} />
      </div>
    </div>
  );
};

export default AttendeeDetail;
